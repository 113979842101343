<template>
  <div class="header">
    <span class="logo"></span>
    <span class="menu-list">
        <!-- logo_meili -- 魅力自然logo图 -->
      <!-- white_new_3.png -- 行知自然logo图 -->
        <div style="display: inline-block"><img :src="cdnUrl + '/platform/static/white_new_3.png'" style="height: 3.33rem;vertical-align: middle;margin-right: 1rem;"></div>
        <a @click="$router.push('/')" class="menu-item" style="margin-left: 34px;">首页</a>
        <a @click="$router.push('/')" class="menu-item" >了解我们</a>
        <a @click="$router.push('/')" class="menu-item" >我们的优势</a>
        <a @click="$router.push('/')" class="menu-item" >特邀专家</a>
      </span>
  </div>
</template>

<script>
export default {
  name: 'ComHeader',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped lang="less">
.header{
  height: 70px;
  position: fixed;
  z-index: 99;
  line-height: 40px;
  color: #FFFFFF;
  width: 100%;
  background: rgba(0,0,0,0.4);
}
.header.scroll-bottom{
  background: rgba(0, 128, 0, 0.8);
}
.header .logo{
  width: auto;
  height: 36px;
  font-size: 3.33rem;
  font-weight: 400;
  color: #FFFFFF;
  letter-spacing: 20px;
  padding-left: 8.33rem;
}
.header .menu-list {
  height: 60px;
  line-height: 60px;
  display: inline-block;
}
.header .menu-list .menu-item {
  height: 70px;
  font-size: 16px;
  color: #FFFFFF;
  margin-right: 34px;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 4px;
  letter-spacing: 4px;
  text-align: center;
  padding-left: 4px;
}
.header .menu-list .menu-item.active{
  border-bottom: 2px solid #FFFFFF;
}
</style>
