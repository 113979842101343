<template>
  <div class="tour-itinerary">
    <div class="section1">
      <div style="width: 1200px;margin-left: auto;margin-right: auto;display: flex">
        <div>
          <img style="height: 509px;width: auto;margin-left: 26px" :src="cdnUrl + '/platform/static/activity/jieshao3.png'"/>
        </div>
        <div>
          <img style="height: 181px" :src="cdnUrl + '/platform/static/activity/jieshao2.png'"/>
          <img style="height: 382px;" :src="cdnUrl + '/platform/static/activity/jieshao1.png'"/>
        </div>
      </div>
    </div>
    <div class="section2">
      <img style="height: 100px;width: auto;margin-left: auto;margin-right: auto;margin-top: 10px" :src="cdnUrl + '/platform/static/activity/jiudianfangxing.png'"/>
      <div class="pics-wrap">
        <RoomSwiper style="width: 1200px;margin: 0 auto"></RoomSwiper>
      </div>
    </div>
    <div style="height: 60px"></div>
  </div>
</template>

<script>
import RoomSwiper from '@/components/activity/RoomSwiper.vue'
export default {
  name: 'TourItinerary',
  components: {
    RoomSwiper
  },
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.tour-itinerary{
  margin-top: 47px;
  .section1{
    height: 610px;
    background: #E1EAC6;
  }
  .section2{
    text-align: center;
    .pics-wrap{
      height: 300px;
      background: #FCDF7C;
      padding-top: 50px;
      box-sizing: border-box;
    }
  }
}
</style>
