<template>
  <div class="upgrade-services">
    <img class="shengji1" :src="cdnUrl + '/platform/static/activity/shengji1.png'"/>
    <br/>
    <img class="shengji2" :src="cdnUrl + '/platform/static/activity/shengji2.png'"/>
  </div>
</template>

<script>
export default {
  name: 'UpgradeServices',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped lang="less">
.upgrade-services{
  margin-top: 100px;
  position: relative;
  .shengji1{
    width: 1200px;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .shengji2{
    width: 350px;
    margin-left: calc((100vw - 1200px) / 2 - 60px);
  }
}
</style>
