<template>
  <div class="platform-introduction">
    <!--第一部分-->
<!--    <div class="section1">
      <div style="display: flex">
        <div style="flex: 1;position: relative">
          <img class="p1-img" :src="cdnUrl + '/platform/static/activity/j1.png'">
          <img class="p2-img" style="position:absolute;left: 180px;
    top: 80px;box-shadow: 0px 0px 20px #A2BE4B;" :src="cdnUrl + '/platform/static/activity/j2.png'">
        </div>
        <div style="width: 647px" class="right-box">
          <img :src="cdnUrl + '/platform/static/activity/j3.png'">
          <div class="long-desc">
            央视网是中国网络电视台旗下互联网站业务，也是中央重点新闻网站，面向全球、多终端、立体化的新闻信息共享平台。
            央视网熊猫频道，创办于2013年8月6日，是全球首个24小时直播大熊猫的网络专属频道，全天候向全球直播大熊猫的日常生活、繁育和成长情况。
            熊猫频道是一个网络视频媒体，以国宝大熊猫为主要载体，以大熊猫互动直播、点播、纪录片、图片等节目为主要内容，包含中英双语，集网站、社区、社交媒体等多种形式的主题新媒体集群，是全球唯一的大熊猫主题社区网络频道。
            2013年底，熊猫频道凭借其创新性和独特性在首届中国网络视听大会上获得两项殊荣：“网络视听创新典范”和“网络视频十佳品牌栏目”，迅速受到海内外互联网界和传媒界的关注。
            目前，熊猫频道在全球拥有粉丝近4000万，覆盖230多个国家和地区，官方微博粉丝1000多万。在传播大熊猫文化、推广大熊猫经济方面具有强大的影响力。
          </div>
        </div>
      </div>
    </div>-->
    <div class="section2" style="padding-top: 40px;padding-bottom: 40px">
      <div style="display: flex">
        <div class="left-box">
          <img :src="cdnUrl + '/platform/static/activity/j4.png'">
          <div class="long-desc">
            行知自然教育平台是依托央视网熊猫频道、中国野生动物保护协会和汉雅星空在野生动植物保护领域的专业能力和资源优势以及传播影响力、内容制作、宣传推广、平台运营、技术保障等各类优势，三方共同打造的国家级教育平台。为幼儿园、中小学搭建了一个可定制、可分级、可持续更新的国际化自然教育平台。它不仅能够为校内自然教育课程补充教学资源，拓展教学手段，而且还能够为校内家教协同、校外研学实践活动提供高质量的解决方案。
            <br/>
            <br/>
            行知自然拥有上百名来自国内顶尖高校的权威动植物专家导师，为学生量身打造专属自然课程、线下科普讲座，带领“小小科学家”们进行野外科考研学。
          </div>
        </div>
        <div style="flex: 1;position: relative">
          <img class="p2-img" style="position:absolute;right: 0px;z-index: -1;
    top: 80px;" :src="cdnUrl + '/platform/static/activity/j6.png'">
          <img class="p1-img" style="position:absolute;right: 210px;box-shadow: 0px 0px 20px #A2BE4B;" :src="cdnUrl + '/platform/static/activity/j5.png'">
        </div>
      </div>
    </div>
    <!--第三部分-->
    <div class="section3">
      <div class="title-box">
        <div class="long-desc">
          <img style="margin-top: 40px;margin-bottom: 20px" :src="cdnUrl + '/platform/static/activity/j7.png'"><br/>
          活动全程会有专业摄影师跟踪记录拍摄，随时为营员们记录在基地的点滴瞬间，为此次研学之旅留下专属记忆。所拍内容将有机会制作成节目在“iPanda熊猫频道”及行知自然平台播出，分享美好时光。
          <br/>
          <br/>
          同时，营员们参加研学活动所完成的照片、视频、绘画、手工、文章等作品，将有机会优先参加行知自然平台开展的相关作品征集展示活动。
        </div>
      </div>
      <div style="height: 50px"></div>
      <img :src="cdnUrl + '/platform/static/activity/j8.png'" style="max-height: 943px;height: auto;display: block;margin: 0 auto;">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformIntroduction',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped lang="less">
.platform-introduction{
  margin-top: 46px;
  box-sizing: border-box;
  .section1{
    width: 1200px;
    display: block;
    margin: 0 auto;
    .p1-img, .p2-img{
      width: 300px;
      height: auto;
    }
    .right-box{
      img{
        height: 41px;
        width: auto;
        margin-bottom: 10px;
      }
      .long-desc{
        height: 319px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
  }
  .section2{
    width: 1200px;
    display: block;
    margin: 0 auto;
    .left-box{
      width: 647px;
      img{
        height: 41px;
        width: auto;
        margin-bottom: 10px;
      }
      .long-desc{
        height: 234px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
  }
  .section3{
    height: 953px;
    position: relative;
    overflow: hidden;
    .title-box{
      height: 323px;
      background: #FCDF7C;
      border-radius: 0px 153px 153px 0px;
      position: absolute;
      width: 100%;
      top: 0px;
      right: calc((100vw - 1200px) / 2 + 350px);
      .long-desc{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        width: 800px;
        float: right;
        padding-right: 50px;
      }
    }
  }
}
</style>
