<template>
  <div class="activity-introduction">
    <!--第一部分-->
    <div class="content-wrap">
      <img :src="cdnUrl + '/platform/static/activity/long_back.png'" class="back-img"/>
      <div class="content-box">
        <div style="padding-top: 20px;padding-left: 40px;padding-right: 40px;display: flex;justify-content: space-between">
          <img style="height: 322px" :src="cdnUrl + '/platform/static/activity/activity1.png'"/>
          <img style="height: 322px" :src="cdnUrl + '/platform/static/activity/activity2.png'"/>
        </div>
        <div class="bottom-desc">清晨，可爱的长颈鹿从窗户伸进脑袋和你共进早餐，并在不经意间偷偷卷走你桌盘里的苹果派和胡萝卜，奇妙之旅从这里开始！</div>
      </div>
    </div>
    <!--第二部分-->
    <div class="section2-box">
      <div class="desc-info desc-info1">
        <img  :src="cdnUrl + '/platform/static/activity/p1.png'"/>
        <div class="describe">室外温泉与网红泳池是舒缓身心的能量场，与长颈鹿同框游泳泡温泉，每一寸肌肤都呼吸着自由<br/>的空气。（具体开放时间请咨询贴身管家）</div>
      </div>
      <div class="desc-info desc-info2">
        <div class="describe">在动物王国和宝贝了解鹦鹉的节奏感到底有多强，袋鼠的睡姿有多妖娆， 非洲白狮与游客的默契<br/>互动，大熊猫好懂享受，在欧乐堡动物王国感受非洲治愈系大草原。</div>
        <img :src="cdnUrl + '/platform/static/activity/p2.png'"/>
      </div>
      <div class="desc-info desc-info3">
        <img :src="cdnUrl + '/platform/static/activity/p3.png'"/>
        <div class="describe">自驾车行区Luck road，体验穿越肯尼亚非洲大草原的快乐，打卡幸运鹿谷收获满满幸运~</div>
      </div>
      <div class="desc-info desc-info4">
        <div class="describe">搭上云霄缆车，健硕的白狮、东北虎就在脚下， 辽阔的非洲草原，大族群式长颈鹿、斑马跟你亲<br/>密say hi，感受非洲原生态，享自然野趣！</div>
        <img :src="cdnUrl + '/platform/static/activity/p4.png'"/>
      </div>
    </div>
    <!--第三部分-->
    <div class="section3-box">
      <img  class="big-img"  style="max-width: 1560px" :src="cdnUrl + '/platform/static/activity/ruzhu_back.png'"/>
      <div class="long-desc">
        <img :src="cdnUrl + '/platform/static/activity/msg_box.png'"/>
        <div class="desc">
          1、套餐内容：包含动物王国两天一夜套餐项目；<br/>
          2、动物王国专属代步车，先人一步抢先入园（车辆拥有两日使用权）；<br/>
          3、主厨定制健康营养餐（因季节变化，用餐时间有差异，详情请入住时咨询管家）<br/>
          早餐：前往长颈鹿庄园绿叶子餐厅；<br/>
          晚餐：前往长颈鹿庄园绿叶子餐厅享用自助餐并加赠一条烤鱼（清江鱼）；<br/>
          4、早餐可与长颈鹿近距离互动，长颈鹿庄园A座房间可远观长颈鹿；<br/>
          5、入住湖畔垂钓木屋配有专属垂钓位(钓具可自备或租借，租赁200元/杆）；<br/>
          6、4D影院、云霄缆车，十多项亲子游乐设施免排队任意玩，入住期间在开放时间内可不<br/>
          限次数体验;乘坐游乐设备，出示权益护照免排队；<br/>
          7、赠送两份长颈鹿投喂树叶(投喂地点:长颈鹿庄园绿叶子餐厅)；<br/>
          8、入住期间免费使用长颈鹿酒店的温泉泳池；<br/>
          9、游玩期间可二次入园；<br/>
          10、房间内零食、饮品免费享用；<br/>
          11、如遇儿童当天生日，赠送8寸专属定制蛋糕(以身份证信息为准，并需提前一天预约)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityIntroduction',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.activity-introduction{
  padding-top: 42px;
  position: relative;
  .back-img{
    height: 415px;
    width: 100%;
    position: absolute;
  }
  .content-wrap{
    position: relative;
  }
  .content-box{
    position: relative;
    background: #A2BE4A;
    width: 1200px;
    margin: auto;
    height: 415px;
    box-sizing: border-box;
    .bottom-desc{
      height: 74px;
      width: 1200px;
      box-sizing: border-box;
      background: linear-gradient(265deg, #A2BE4B 0%, #7EA700 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      line-height: 74px;
      font-size: 20px;
      color: #FFFFFF;
      padding: 0 20px;
    }
  }
  .section2-box{
    width: 1200px;
    margin: auto;
    padding: 40px 0px;
    .desc-info{
      position: relative;
      display: flex;
      img{
        height: 220px;
        width: auto;
      }
      .describe{
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        display: inline-block;
        padding-top: 63px;
        width: 774px
      }
    }
  }
  .section3-box{
    position: relative;
    height: 630px;
    .big-img{
      right: calc((100vw - 1200px) / 2);
      position: absolute;
    }
    .long-desc{
      position: relative;
      padding-top: 36px;
      .desc{
        right: calc((100vw - 1200px) / 2);
        position: absolute;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        width: 708px;
        box-sizing: border-box;
        padding-top: 30px;
        padding-left: 50px;
      }
      img{
        right: calc((100vw - 1200px) / 2);
        position: absolute;
      }
    }
  }
}
</style>
