<template>
  <div class="price-detail">
    <img class="price-back" :src="cdnUrl + '/platform/static/activity/price_back.png'" />
    <img class="price-detail" :src="cdnUrl + '/platform/static/activity/price_detail.png'" />
    <div class="section">
      <div class="title">
        <div class="inner-title">往返车票</div>
      </div>
      <div class="show-path">
        <div class="path1">
          <div class="path-title">去程</div>
          <div class="path-detail">
            <img :src="cdnUrl + '/platform/static/activity/green_pao.png'"/>
            <div style="position: absolute;width: 244px;padding-top: 40px;">
              上午10:00-11:23<br/>
              北京南-济南西<br/>
              G7<br/>
              一等座 357元<br/>
              商务座 782<br/>
              1小时23分钟
            </div>
          </div>
        </div>
        <div class="path2">
          <div class="path-title">返程</div>
          <div class="path-detail">
            <img :src="cdnUrl + '/platform/static/activity/yellow_pao1.png'"/>
            <div style="position: absolute;width: 244px;padding-top: 40px;">
              下午16:10-17:36<br/>
              济南西-北京南<br/>
              G16<br/>
              一等座 357元<br/>
              商务座 782<br/>
              1小时26分钟<br/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tips-box">
      <div class="title">门票适用范围:</div>
      <div class="long-desc">
        除别墅外房型：适用于2名成人赠送两2名身高1.4m(不含)以下儿童; 长颈鹿别墅：适用于6名成人赠送4名身<br/>高1.4m(不含)以下儿童。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceDetail',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.price-detail{
  padding-top: 42px;
  position: relative;
  height: 1070px;
  .price-back{
    position: absolute;
    right: calc((100vw - 1200px) / 2 - 100px);
    height: 303px;
    width: auto;
    top: 166px;
  }
  .price-detail{
    position: absolute;
    right: calc((100vw / 2)) ;
    top: 64px;
    height: 624px;
    width: auto;
  }
  .section{
    position: absolute;
    right: calc((100vw - 1200px) / 2);
    top: 216px;
    .title{
      margin-left: auto;
      margin-right: auto;
      width: 306px;
      height: 81px;
      box-shadow: 0px 3px 6px 1px #061B60;
      border-radius: 9px 9px 9px 9px;
      border: 1px solid #FFFFFF;
      text-align: center;
      text-align: center;
      .inner-title{
        padding: 14px 8px;
        width: 114px;
        border-bottom: 1px solid #FFFFFF;
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 33px;
        text-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        color: #FFFFFF;
        margin: auto;
      }
    }
    .show-path{
      display: flex;
      justify-content: space-between;
      padding-top: 25px;
      .path1{
        margin-right: 24px;
        width: 244px;
        .path-title{
          font-size: 20px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 42px;
          width: 244px;
          text-align: center;
        }
        .path-detail{
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          position: relative;
          width: 244px;
          border-radius: 35px;
          height: 318px;
          &:hover{
            box-shadow: 0px 13px 14px 1px #6666;
          }
          img{
            position: absolute;
            width: 244px;
            height: 318px;
            left: 0px;
          }
        }
      }
      .path2{
        width: 244px;
        .path-title{
          box-sizing: border-box;
          margin-left: auto;
          margin-right: auto;
          width: 137px;
          line-height: 42px;
          background: rgba(255,255,255,0.1);
          border-radius: 30px 30px 30px 30px;
          border: 1px solid #FFFFFF;
          text-align: center;
          color: #FFFFFF;
          font-size: 20px;
          font-weight: 500;
        }
        .path-detail{
          border-radius: 35px;
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          color: #A15C23;
          line-height: 40px;
          position: relative;
          height: 318px;
          &:hover{
            box-shadow: 0px 13px 14px 1px #6666;
          }
          img{
            position: absolute;
            width: 244px;
            height: 318px;
            left: 0px;
          }
        }
      }
    }
  }
  .tips-box{
    width: 1200px;
    height: 180px;
    background: #A2BE4B;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 20px 20px 20px 20px;
    right: calc((100vw - 1200px) / 2);
    position: absolute;
    top: 788px;
    padding: 30px;
    box-sizing: border-box;
    .title{
      font-size: 24px;
      text-align: center;
      height: 41px;
      background: #FCDF7C;
      line-height: 41px;
      color: rgba(247, 122, 28, 1);
      width: 166px;
    }
    .long-desc{
      margin-top: 6px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
    }
  }
}
</style>
