<template>
  <div class="registration-notice">
    <div class="inner-wrap">
      <div class="left-desc">
        <div class="top-desc">
          <div class="title">注意事项：</div>
          一经报名则视为认可行程、课程内容及相关安排<br/>
          因出现不可抗力或意外事件（如自然灾害等因素）导致行程无法出行，行程被取消后，活动具体安排可能根据实际情况进行适当调整<br/>
          活动中带队老师可能会为团员拍摄照片或视频，用于活动群内分享、活动回顾、后续活动招募等，如果您不希望我们使用您或您孩子的形象，请于活动前告知我们<br/>
          必须严格遵守相关自然保护区法律法规和野生动物保护条例，遵守保护区内各项规章制度，活动过程须听从工作人员安排，禁止私自活动。
        </div>
        <div class="bottom-desc">
          邮箱：yx@hanyastar.com<br/>
          官网：https://www.gonature.cn/<br/>
          地址：北京市朝阳区西大望路合生汇写字楼403<br/>
          联系电话：010-87772213 、13910006779 、13321166303
        </div>
      </div>
      <img class="img1" :src="cdnUrl + '/platform/static/activity/b1.png'">
    </div>
    <div style="height: 77px"></div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationNotice',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped lang="less">
.registration-notice{
  padding-top: 46px;
  box-sizing: border-box;
  .inner-wrap{
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    .left-desc{
      width: 668px;
      .top-desc{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
        .title{
          font-size: 24px;
          color: rgba(51, 51, 51, 1);
        }
      }
      .bottom-desc{
        margin-top: 62px;
        font-size: 16px;
        font-weight: 500;
        color: #F77A1C;
        line-height: 34px;
      }
    }
  }
}
</style>
