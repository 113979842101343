<template>
  <div class="swiper-main">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide class="swiper-item" v-for="(item, index) in swiperList" :key="index">
        <img class="images" :src="cdnUrl + '/platform/static/activity/room/room'+(index+1)+'.png'" />
        <div class="img-title">{{item}}</div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- 箭头 -->
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

export default {
  name: 'Swiper',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl,
      swiperList: [
        '（二楼）大迁徙梦想滑梯房',
        '（二楼）大迁徙梦想滑梯房',
        '（二楼）非洲草原观景房1',
        '（二楼）非洲草原观景房2',
        '（一楼）丛林酋长房1',
        '（一楼）丛林酋长房2',
        '（一楼）丛林酋长房3',
        '斑马部落大床房',
        '斑马部落野奢房',
        '斑马部落野奢房2',
        '湖畔垂钓木屋1',
        '湖畔垂钓木屋2',
        '马赛阳台景观房',
        '马赛阳台景观房2',
        '长颈鹿别墅1',
        '长颈鹿别墅2',
        '长颈鹿别墅3',
        '长颈鹿别墅4',
        '长颈鹿别墅5'
      ],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 6,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        // pagination: {
        // el: '.swiper-pagination',
        // clickable: true // 允许分页点击跳转
        // }
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted () {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log('this is current swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false);
  }
}
</script>
<style scoped lang="less">
.swiper-main {
  width: 100%;
  height: 167px;
  background: #FFFFFF;
  padding-top: 2px;
}
.swiper-container {
  width: 100%;
  height: 217px;
}
.swiper-container img {
  width: 100%;
  height: 167px;
  vertical-align: bottom;
}
::v-deep .swiper-pagination-bullet-active {
  background-color: #b0352f;
}
.images{
  width: 216px;
  height: 167px;
}
.img-title{
  height: 30px;
  background: #FFFFFF;
  line-height: 30px;
  color: #743500;
  border-right: 1px solid #FCDF7C;
  border-left: 1px solid #FCDF7C;
}
.swiper-item:hover{
  .img-title{
    box-shadow: 0px 3px 6px 1px rgba(208,140,128,0.72);
  }
}
</style>
