<template>
  <div>
    <ComHeader></ComHeader>
    <!--活动banner-->
    <div class="top-banner">
      <div class="try-box ">
        <div class="try-btn try-btn-application" @click="applicationNow()">
          <span class="application-btn">立即报名</span>
          <img :src="cdnUrl + '/platform/static/activity/pointer.gif'" style="height: 140px;width: 140px;position: absolute"/>
        </div>
      </div>
      <div class="activity-desc">
        在这里，不用去肯尼亚就能领略“与动物比邻而居”的非洲草原风情，<br/>
        清晨还会被温柔的长颈鹿叫醒，尊享与它们“共进早餐、同吃同眠”的惬意和自然纯粹的身心治愈
      </div>
      <img :src="cdnUrl + '/platform/static/activity/long_banner.png'" style="width: 100%;height: auto" ref="bannerHeight">
    </div>
    <div class="detail-tabs">
      <div class="detail-menu">
        <div class="menu-item" @click="changeTab(item.key)" :class="{'active': item.key===curMenu}" v-for="item in detailMenu" :key="item.key">
          {{item.title}}
        </div>
      </div>
    </div>
    <div style="min-height: 500px">
      <ActivityIntroduction v-if="curMenu===1"></ActivityIntroduction>
      <PriceDetail v-if="curMenu===2"></PriceDetail>
      <TourItinerary v-if="curMenu===3"></TourItinerary>
      <UpgradeServices v-if="curMenu===4"></UpgradeServices>
      <PlatformIntroduction v-if="curMenu===5"></PlatformIntroduction>
      <SurroundingActivities v-if="curMenu===6"></SurroundingActivities>
      <RegistrationNotice v-if="curMenu===7"></RegistrationNotice>
    </div>
    <Footer></Footer>
    <el-dialog
      style="border-radius: 12px 12px 12px 12px;"
      title="填写报名信息"
      :visible.sync="centerDialogVisible"
      width="40%"
      center>
      <el-form v-show="!isSubmited" ref="subform" :label-position="'right'" label-width="80px" :model="formLabelAlign" :rules="rules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model.trim="formLabelAlign.phone"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNumber">
          <el-input v-model.trim="formLabelAlign.idNumber"></el-input>
        </el-form-item>
      </el-form>
      <div class="res-show" v-if="isSubmited">
        <img :src="cdnUrl + '/platform/static/activity/success.png'">
        <div class="res-status">报名成功！</div>
        <div class="info">
          您已成功报名<br/>
          我们将于24小时之内通过电话联系您
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isSubmited">
        <el-button class="app_btn" type="primary" @click="submitForm()">确认报名</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { applactionForm } from '@/api/api'
import Footer from '@/components/Footer.vue'
import ComHeader from '@/components/ComHeader.vue'
import ActivityIntroduction from '@/components/activity/ActivityIntroduction.vue'
import PriceDetail from '@/components/activity/PriceDetail.vue'
import TourItinerary from '@/components/activity/TourItinerary.vue'
import UpgradeServices from '@/components/activity/UpgradeServices.vue'
import PlatformIntroduction from '@/components/activity/PlatformIntroduction.vue'
import SurroundingActivities from '@/components/activity/SurroundingActivities.vue'
import RegistrationNotice from '@/components/activity/RegistrationNotice.vue'
export default {
  name: 'ActivityDetail',
  components: {
    ComHeader,
    ActivityIntroduction,
    Footer,
    PriceDetail,
    TourItinerary,
    UpgradeServices,
    PlatformIntroduction,
    SurroundingActivities,
    RegistrationNotice
  },
  data () {
    var checkPhone = (rule, value, callback) => { // 手机号验证
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }

    return {
      isSubmited: false, // 是否提交通过
      centerDialogVisible: false,
      formLabelAlign: {
        name: '',
        phone: '',
        idNumber: ''
      },
      cdnUrl: this.$store.state.cdnUrl,
      detailMenu: [
        { title: '活动介绍', key: 1 },
        { title: '价格明细', key: 2 },
        { title: '行程介绍', key: 3 },
        { title: '升级服务', key: 4 },
        { title: '平台介绍', key: 5 },
        { title: '周边游玩', key: 6 },
        { title: '报名须知', key: 7 }
      ],
      curMenu: 1,
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号码格式不正确', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    applicationNow () {
      this.centerDialogVisible = true
    },
    changeTab (key) {
      this.curMenu = key
    },
    submitForm () {
      const formName = 'subform'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subApplactionForm()
        } else {
          this.$message({
            message: '您填写的信息有误，请检查！',
            type: 'warning'
          })
          this.loading = false
          return false
        }
      })
    },
    async subApplactionForm () {
      const res = await applactionForm(this.formLabelAlign)
      if (res.code === 0) {
        this.$message({
          message: '报名成功，请等待客服人员审核并与您联系！',
          type: 'success'
        })
        this.formLabelAlign = {
          name: '',
          phone: '',
          idNumber: ''
        }
        this.isSubmited = true
      } else {
        if (res.code === 100002) {
          this.$message.error('当前身份证号已经提交报名信息！')
        } else {
          this.$message.error('报名失败！')
        }
      }
      this.loading = false
    }
  },
  mounted () {
    console.info(this.$route.query.sign)
    if (this.$route.query.sign && this.$route.query.sign === '1') {
      this.applicationNow()
    }
  }
}
</script>

<style scoped lang="less">
.res-show{
  padding-top: 30px;
  text-align: center;
  .res-status{
    margin-top: 10px;
    font-size: 30px;
    font-weight: 500;
    color: #333333;
  }
  .info{
    font-size: 26px;
    font-weight: 400;
    color: #FC5700;
    margin-top: 20px;
  }
}
/deep/ .el-dialog{
  border-radius: 12px;
}
.app_btn{
  height: 50px;
  background: linear-gradient(90deg, #FC7E00 0%, #FC5700 100%);
  border-radius: 28px 28px 28px 28px;
  line-height: 50px;
  width: 180px;
  border: 0px;
  font-size: 20px;
  font-weight: 500;
  padding: 0px;
}
.top-banner{
  position: relative;
  min-height: 400px;
  .try-box{
    position: absolute;
    height: 80px;
    background: linear-gradient(90deg, #FC7E00 0%, #FC5700 100%);
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    top: 65%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    .try-btn-application{
      width: 260px;
      text-align: center;
      font-size: 40px;
      line-height: 80px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }
  }
  .activity-desc{
    box-sizing: border-box;
    width: 100%;
    height: 10px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6)100%);
    border-radius: 0px 0px 0px 0px;
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    padding-top: 22px;
    position: absolute;
    bottom: 3px;
  }
}
.detail-tabs{
  min-width: 1200px;
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
  .detail-menu{
    width: 1200px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    padding-bottom: 4px;
    border-bottom: 2px solid #E0E0E0;
    height: 56px;
    line-height: 56px;
    color: #666666;
    display: flex;
    justify-content: center;
    .menu-item{
      width: 160px;
      box-sizing: border-box;
      text-align: center;
      font-size: 26px;
      cursor: pointer;
      &:hover{
        background: linear-gradient(90deg, #FC7E00 0%, #FC5700 100%);
        border-radius: 28px 28px 28px 28px;
        opacity: 0.8;
        color: #ffffff;
      }
      &.active{
        background: linear-gradient(90deg, #FC7E00 0%, #FC5700 100%);
        border-radius: 28px 28px 28px 28px;
        color: #ffffff;
      }
    }
  }
}
</style>
