<template>
  <div class="surrounding-activities">
    <!--第一部分-->
    <div class="section1">
      <img class="img1" :src="cdnUrl + '/platform/static/activity/w1.png'">
      <div class="text-desc">
        <span style="font-size: 30px">泉城欧乐堡度假区</span>,位于素有黄河水乡、济南后花园”之称的山东齐河县黄河国际生态城域内，总占地10.3平方公里，与济南仅一河(黄河)之隔，高铁济南西站距离景区15分钟车程，济南遥墙机场距离景区45分钟车程，30分钟车程即可到达济南市中心。游玩项目包涵：
        <span>欧乐堡动物王国、欧乐堡梦幻世界、欧乐堡海洋极地世界、欧乐堡水上世界</span>，还有<span>黄齐河博物馆群</span>等。
      </div>
      <div class="desc-block">
        <img :src="cdnUrl + '/platform/static/activity/w2.png'"/>
        <div class="long-desc">
          <span>欧乐堡梦幻世界</span>有超豪华转马、旋转飞椅、桑巴气球、碰碰车、太空漫步、转转杯、4D影院、露营、马术以及剧场演艺等二十余款适宜全年龄层游玩的精彩项目，不仅是孩子梦想的天地、更是让中老年人找回童趣的乐园，欧乐堡将成为全家人共享欢乐的梦幻世界！
        </div>
      </div>
    </div>
    <!--第二部分-->
    <div class="section2">
      <div class="left-text">
        <span>欧乐堡海洋极地世界 </span>拥有热带雨林、海底世界、极地王国、科普展厅、水下剧场、海洋剧场等十多个主题分区。汇集北极熊、白鲸、海豚、企鹅等世界各地的海洋生物、南北两极动物、珍稀鱼类上百种不仅可以见证海洋动物的诞生，还可以与美人鱼小姐姐面对面。
      </div>
      <img class="img1" :src="cdnUrl + '/platform/static/activity/w3.png'">
      <img class="img2" :src="cdnUrl + '/platform/static/activity/w4.png'">
      <img class="img3" :src="cdnUrl + '/platform/static/activity/w5.png'">
      <img class="img4" :src="cdnUrl + '/platform/static/activity/w6.png'">
      <img class="img5" :src="cdnUrl + '/platform/static/activity/w7.png'">
    </div>
    <div class="section3">
      <div class="imgs-wrap">
        <img class="img1" :src="cdnUrl + '/platform/static/activity/w8.png'">
        <img class="img2" :src="cdnUrl + '/platform/static/activity/w9.png'">
        <img class="img3" :src="cdnUrl + '/platform/static/activity/w10.png'">
      </div>
    </div>
    <div style="height: 45px"></div>
    <div class="section4">
      <div class="long-desc">
        <span>欧乐堡动物王国</span>秉承“拥抱奇趣自然”的理念，集野生动物观赏互动、亲子游乐体验、科普研学旅游、动植物养殖繁育、保护研究为一体。园区规划建设亚洲森林、狂野非洲、鹦鹉世界、熊猫岛、萌宠奇遇、灵长王国、飞鸟奇缘、长颈鹿庄园酒店、湖畔木屋、动物行为展示馆、亲子拓展区、奇妙博物馆等多个主题功能分区，为游客们展现野生动物“大种群”“族群式”的原生态生活状态。“一园一河”的游览布局，自驾车、高空缆车的立体观览模式，以及黄河文化古生物化石博物馆等特色体验，让广大市民尽享自然野趣，带来国际水准的亲子度假新体验。
      </div>
    </div>
    <div style="height: 45px"></div>
    <div class="section5">
      <div style="flex: 1">
        <img style="margin-right: 10px" class="img1" :src="cdnUrl + '/platform/static/activity/w11.png'">
        <img class="img2" :src="cdnUrl + '/platform/static/activity/w12.png'">

      </div>
      <div class="right-txt">
        <span>珍稀动物标本博物馆</span>模拟和复原了珍稀野生动物 原生态的的生存场景，展出标本几乎囊括了世界各 地的飞禽走兽，有助于学生深入了解大自然资源的 多样性、七大洲四大洋不同栖息地的不同生物等， 加深对自然资源的保护意识，促进青少年爱护自然 生态、关爱保护动物，与大自然和谐相处。白天孩 子们畅翔在珍稀动物的世界里；晚上还可以夜宿标 本博物馆，感受现实版的博物馆奇妙夜，边玩边 学，度过轻松愉快的假期。
      </div>
    </div>
    <div style="height: 45px"></div>
    <div class="section6">
      <img class="img1" :src="cdnUrl + '/platform/static/activity/w13.png'">
      <div class="inner-wrap">
        <img class="img2" :src="cdnUrl + '/platform/static/activity/w14.png'">
        <div class="long-desc">
          <span>古生物化石博物馆</span>展示各个地质时期保存完好的古生物化石3000余件以及大量远古生物研究资料，如黄河象、巨犀、豕脊齿象门齿群、连体和政羊头骨等。不同主题的特色研学课程让学生穿越古今几亿年，从地球的起源开始，认识地球环境变化与地球生物的进化过程。
        </div>
        <img class="img3" :src="cdnUrl + '/platform/static/activity/w15.png'">
      </div>
      <img class="img4" :src="cdnUrl + '/platform/static/activity/shuye.png'">
    </div>
    <div style="height: 45px"></div>
    <div class="section7">
      <div class="long-desc">
        <span>黄河文化博物馆群</span>项目由清华大学建筑历史与文物建筑保护研究所所长、中国圆明园学会园林古建分会会长王贵祥教授及其团队历经八年规划设计；以中国传统文化为载体，将古典建筑与古典园林艺术有机结合，20个博物馆设计仿照汉唐宋元明清历朝历代、进入中国人心灵世界、且产生民族情感、已被战火毁掉的标志性古代建筑，将其等尺度复原；并融入各个历史时期的思想、美术、雕塑、壁画、民俗、园林艺术、建筑艺术之精华，通过山水造园营造手法和宏大的山水格局，建成集传统建筑、自然文化、教育成长三位一体的大型古典园区，是中国高品质、高品位的历史文化与古典建筑的艺术综合体。
      </div>
      <img class="img1" :src="cdnUrl + '/platform/static/activity/w16.png'">
    </div>
    <div style="height: 32px"></div>
    <div style="background: #F2F2F2;">
      <div class="section8">
        <img class="img1" :src="cdnUrl + '/platform/static/activity/w17.png'">
        <div style="width: 500px;height: 310px;position: relative">
          <div class="show-desc">
            1、优待票范围：<br/>
             （1）1.2（含）-1.4（不含）米之间的儿童。<br/>
             （2） 65 周岁以上持老年证或身份证的老年人。<br/>
             （3） 持军官证或士兵证的现役军人。<br/>
             （4） 特残证、残疾证、和可视性残疾人。<br/>
            2、1.2米（含）及以上人士均需购索，一位成人可以免费带<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;一位1.2米（不含）以下的儿童，多带一位需补差100元/人。<br/>
          </div>
          <img class="img2" :src="cdnUrl + '/platform/static/activity/w18.png'">
        </div>
      </div>
    </div>
    <div style="height: 45px"></div>
    <div class="section9">
      <img class="img1" :src="cdnUrl + '/platform/static/activity/w19.png'">
      <img class="img2" :src="cdnUrl + '/platform/static/activity/w20.png'">
    </div>
    <div style="height: 45px"></div>
  </div>
</template>

<script>
export default {
  name: 'SurroundingActivities',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  }
}
</script>

<style scoped lang="less">
.surrounding-activities{
  padding-top: 40px;
  box-sizing: border-box;
  .section1{
    height: 608px;
    position: relative;
    .img1{
      width: 725px;
      height: auto;
      position: absolute;
      right: calc((100vw - 1200px) / 2 + 835px);
    }
    .text-desc{
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      width: 874px;
      position: absolute;
      right: calc((100vw - 1200px) / 2);
      top: 81px;
      span{
        color: rgba(247, 122, 28, 1);
      }
    }
    .desc-block{
      width: 967px;
      height: 385px;
      position: absolute;
      right: calc((100vw - 1200px) / 2);
      top: 253px;
      img{
        width: 967px;
        height: 385px;
      }
      .long-desc{
        width: 481px;
        font-size: 18px;
        font-weight: 400;
        color: #F8FFE2;
        line-height: 34px;
        text-shadow: 0px 3px 6px rgba(0,0,0,0.36);
        position: absolute;
        top: 110px;
        right: 60px;
        span{
          color: rgba(255, 223, 44, 1);
          font-size: 26px;
        }
      }
    }
  }
  .section2{
    position: relative;
    padding-top: 100px;
    .left-text{
      width: 586px;
      height: 210px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      position: absolute;
      top: 92px;
      right: calc((100vw - 1200px) / 2 + 610px);
      span{
        height: 45px;
        background: #F77A1C;
        font-size: 26px;
        line-height: 45px;
        padding: 0 6px;
        color: #ffffff;
      }
    }
    .img1{
      width: 170px;
      height: auto;
      position: absolute;
      right: calc((100vw - 1200px) / 2 + 86px);
      top: -10px;
    }
    .img2{
      width: 225px;
      height: auto;
      right: calc((100vw - 1200px) / 2 + 354px);
      position: absolute;
      top: 20px;
    }
    .img3{
      width: 341px;
      height: auto;
      right: calc((100vw - 1200px) / 2 + 67px);
      position: absolute;
      top: 163px;
    }
    .img4{
      width: 162px;
      height: auto;
      right: calc((100vw - 1200px) / 2 + 499px);
      position: absolute;
      top: 291px;
    }
    .img5{
      display: block;
      margin: 0 auto;
    }
  }
  .section3{
    width: 1200px;
    margin: 0 auto;
    .imgs-wrap{
      transform: translateY(0px);
      margin-top: -240px;
      display: flex;
      justify-content: space-between;
    }
    .img1{
      width: auto;
      height: 269px;
    }
    .img2{
      width: auto;
      height: 269px;
    }
    .img3{
      width: auto;
      height: 269px;
    }
  }
  .section4{
    width: 1200px;
    margin: 0 auto;
    .long-desc{
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      span{
        height: 46px;
        background: #F77A1C;
        line-height: 46px;
        padding: 0 6px;
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }
  .section5{
    width: 1200px;
    margin: 0 auto;
    height: 278px;
    background: #FCDF7C;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    .right-txt{
      width: 600px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      span{
        height: 46px;
        background: #F77A1C;
        line-height: 46px;
        padding: 0 6px;
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }
  .section6{
    position: relative;
    min-height: 355px;
    box-sizing: border-box;
    .img1{
      position: absolute;
      left: 0px;
      width: 221px;
      height: 359px;
    }
    .inner-wrap{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .img2{
        width: 289px;
        height: 169px;
        margin-right: 20px;
      }
      .img3{
        width: 525px;
        height: 289px;
        margin-left: 20px;
      }
      .long-desc{
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
        span{
          height: 46px;
          background: #F77A1C;
          line-height: 46px;
          padding: 0 6px;
          font-size: 26px;
          color: #FFFFFF;
        }
      }
    }
    .img4{
      width: 909px;
      height: 66px;
      position: absolute;
      right: calc((100vw - 1200px) / 2);
    }
  }
  .section7{
    height: 339px;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .img1{
      height: 339px;
      float: right;
    }
    .long-desc{
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      width: 707px;
      position: absolute;
      top: 38px;
      left: 0px;
      span{
        height: 46px;
        background: #F77A1C;
        line-height: 46px;
        padding: 0 6px;
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }
  .section8{
    min-height: 483px;
    background: #F2F2F2;
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    align-items: center;
    .show-desc{
      position: absolute;
      padding-top: 47px;
      padding-left: 48px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
  }
  .section9{
   position: relative;
    text-align: center;
    .img1{
      width: 1200px;
      margin: 0 auto;
      position: relative;
    }
    .img2{
      width: 135px;
      height: 205px;
      position: absolute;
      right: 0px;
      top: 196px;
    }
  }
}
</style>
